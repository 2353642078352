* {
  font-family: Gilroy;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

:root {
  --main-blue: #3e78fa;
  --link: #0089ed;
  --gray: #718096;
  --main-blue-hover: #1c60f9;
  --white: #ffffff;
  --second-text: #525f7f;
  --second-color: rgba(82, 95, 127, 0.3);
  --primary-text: #153561;
}

button {
  cursor: pointer;
}

.custom-cursor,
.custom-cursor * {
  cursor: url("assets/img/Coursor_mouse_default.svg") 12 0, move !important;
}

.custom-cursor-incorrect,
.custom-cursor-incorrect * {
  cursor: url("assets/img/Coursor_mouse_incorrect_answer.svg") 12 0, move !important;
}

.custom-cursor-correct,
.custom-cursor-correct * {
  cursor: url("assets/img/Coursor_mouse_correct_answer.svg") 12 0, move !important;
}
.custom-cursor .card-locked,
.custom-cursor .card-locked * {
  cursor: not-allowed !important;
}

body {
  margin: 0;
  font-family: var(--font-family);
  min-height: 100vh;
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;
  scrollbar-color: var(--main-blue) #e2f3ff;
}

.modalOpen {
  overflow: hidden;
}

.p-toolbar {
  padding: 0.5rem;
}

.p-dialog-mask,
.p-dialog-mask .p-dropdown {
  cursor: default;
}

.p-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-dropdown-panel {
  background-color: #f0f8ff;
  border-radius: 12px;
  margin-top: 7px;
  cursor: pointer;
}

.p-dropdown-items {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-dropdown-item {
  font-size: 18px;
  font-weight: 500;
  padding: 0 !important;
  line-height: normal;
  transition: all 0.3s !important;
}

.admin-page .p-dropdown-item,
.p-dialog-mask .p-dropdown-item,
.p-dropdown-panel .p-dropdown-item {
  cursor: pointer;
}

.p-dropdown-item:first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.p-dropdown-item:last-child {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.p-dropdown-item:hover,
.p-dropdown-item:active {
  background-color: #ddeefc !important;
  color: var(--main-blue) !important;
}

.p-highlight {
  background-color: #e2f3ff !important;
}

.p-paginator-pages .p-highlight {
  background-color: #5489ff !important;
  color: var(--white) !important;
}

.p-paginator-pages {
  background-color: var(--white);
}

.p-paginator {
  background-color: transparent;
}

.p-paginator-page {
  border-radius: 10px !important;
  font-family: Gilroy !important;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  color: #aec2df !important;
  width: 44px !important;
  height: 44px !important;
  transition: all 0.3s !important;
}

.admin-page .p-paginator-page {
  cursor: pointer;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background-color: rgba(84, 137, 255, 0.7);
  color: var(--white) !important;
}

.p-paginator-prev {
  width: 56px !important;
  height: 56px !important;
  background-color: #e7f4ff !important;
  border-radius: 10px !important;
  background-image: url("./assets/img/icons/prev-default-icon.svg") !important;
  transition: all 0.3s !important;
}

.admin-page .p-paginator-prev {
  cursor: pointer;
}

.p-paginator-prev.p-disabled {
  background-image: url("./assets/img/icons/prev-disabled-icon.svg") !important;
}

.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover {
  background-image: url("./assets/img/icons/prev-hover-icon.svg") !important;
}

.p-paginator-next {
  width: 56px !important;
  height: 56px !important;
  background-color: #e7f4ff !important;
  border-radius: 10px !important;
  background-image: url("./assets/img/icons/next-default-icon.svg");
  transition: all 0.3s !important;
}
.p-confirm-popup {
  cursor: default;
}
.admin-page .p-paginator-next {
  cursor: pointer;
}

.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
  background-image: url("./assets/img/icons/next-hover-icon.svg");
}

.p-paginator-next.p-disabled {
  background-image: url("./assets/img/icons/next-disabled-icon.svg");
}

.p-paginator-icon {
  display: none;
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/Gilroy-Thin.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/Gilroy-ThinItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/Gilroy-UltraLight.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 200;
  src: url("./assets/fonts/Gilroy-UltraLightItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Gilroy-Light.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/Gilroy-LightItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Gilroy-RegularItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Gilroy-MediumItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Gilroy-Semibold.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/Gilroy-SemiboldItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Gilroy-BoldItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/Gilroy-Extrabold.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 800;
  src: url("./assets/fonts/Gilroy-ExtraboldItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/Gilroy-Heavy.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/Gilroy-HeavyItalic.ttf");
}
